import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(Draggable) 


function isTouchDevice() {
  return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

// console.log(isTouchDevice())

if(!isTouchDevice()) { 
  Draggable.create(".draggable", {
    inertia: true,
  });
}



function Marquee(selector, speed) {
    const parentSelector = document.querySelector(selector);
    const clone = parentSelector.innerHTML;
    const firstElement = parentSelector.children[0];
    let i = 0;
    parentSelector.insertAdjacentHTML('beforeend', clone);
    parentSelector.insertAdjacentHTML('beforeend', clone);
  
    setInterval(function () {
      firstElement.style.marginLeft = `-${i}px`;
      if (i > firstElement.clientWidth) {
        i = 0;
      }
      i = i + speed;
    }, 0);
  }
  
  //after window is completed load
  //1 class selector for marquee
  //2 marquee speed 0.2
  window.addEventListener('load', Marquee('.marquee', 0.2))


  // sandwich follow movement

  const sandie = document.querySelector(".cursor-follower");
  if(sandie){
    gsap.to(sandie, {opacity:0})
    let cursorTimout 
    document.addEventListener('pointermove', function(e){
     
        //   gsap.to(cursor, {opacity:.5})
        gsap.to(sandie, {opacity:1})
        clearTimeout(cursorTimout);

        var x = e.clientX;
        var y = e.clientY;
        // cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        sandie.style.left = x + 'px';
        sandie.style.top = y + 'px';

        cursorTimout = setTimeout(() => {
            // gsap.to(cursor, {opacity:0})
                gsap.to(sandie, {opacity:0})
            },
        1000
        );
    });
  }

  